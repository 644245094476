import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { Arquivo, AuditoriaItemTesteControleConstatacao, AuditoriaTesteControleConstatacaoService } from 'src/app/services/auditoria-teste-controle-constatacao.service';
import { saveAs as importedSaveAs } from "file-saver";

@Component({
  selector: 'app-modal-anexo',
  templateUrl: './modal-anexo.page.html',
  styleUrls: ['./modal-anexo.page.scss'],
})
export class ModalAnexoPage implements OnInit {
  arquivos: Arquivo[] = [];
  arquivos_evidencia: Arquivo[] = [];

  tipoAnexo: string = "SI";

  registro: AuditoriaItemTesteControleConstatacao = {} as AuditoriaItemTesteControleConstatacao;

  constructor(
    private modalController: ModalController,
    private params: NavParams,
    private auditoriaTesteControleConstatacaoService: AuditoriaTesteControleConstatacaoService,

  ) { }

  ngOnInit() {
    this.registro = this.params.data.registro;
    if ((this.params.data.arquivos !== null) && (!!this.params.data.arquivos[0].id)) {
      this.arquivos = this.params.data.arquivos;
    } else {
      this.arquivos = undefined
    }
    if ((this.params.data.arquivos_evidencia !== null) && (!!this.params.data.arquivos_evidencia[0].id)) {
      this.arquivos_evidencia = this.params.data.arquivos_evidencia;
    } else {
      this.arquivos_evidencia = undefined
    }
  }

  async closeModal() {
    await this.modalController.dismiss();
  }

  downloadFile(arquivo: Arquivo) {
    this.auditoriaTesteControleConstatacaoService.getAuditoriaArquivo(
      arquivo.id
    ).subscribe(
      (retorno) => {
        importedSaveAs(retorno, arquivo.nome)
      },
      (erro) => {
      }
    )
  }

  async deletaArquivoEvidencia(arquivo: Arquivo): Promise<void> {
    await this.auditoriaTesteControleConstatacaoService.deletaAuditoriaArquivoEvidencia(
      this.registro.id,
      arquivo.id
    );
    this.modalController.dismiss({
      atualiza: true
    });
  }

}
