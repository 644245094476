import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { Arquivo, AuditoriaTesteControleConstatacaoService } from 'src/app/services/auditoria-teste-controle-constatacao.service';
import { saveAs as importedSaveAs } from "file-saver";
import { OverlayService } from 'src/app/core/services/overlay.service';
import { AuditoriaTesteSubstantivoConstatacao } from 'src/app/services/auditoria/teste-substantivo-constatacao.service';

@Component({
  selector: 'app-modal-anexo',
  templateUrl: './modal-anexo.page.html',
  styleUrls: ['./modal-anexo.page.scss'],
})
export class ModalAnexoPage implements OnInit {
  arquivos: Arquivo[] = [];
  registro: AuditoriaTesteSubstantivoConstatacao = {} as AuditoriaTesteSubstantivoConstatacao;

  constructor(
    private modalController: ModalController,
    private params: NavParams,
    private auditoriaTesteControleConstatacaoService: AuditoriaTesteControleConstatacaoService,
    private overlayService: OverlayService
  ) { }

  ngOnInit() {
    this.registro = this.params.data.registro;
    if ((this.params.data.arquivos !== null) && (!!this.params.data.arquivos[0].id)) {
      this.arquivos = this.params.data.arquivos;
    } else {
      this.arquivos = undefined
    }
  }

  async closeModal() {
    await this.modalController.dismiss({
      atualiza: false
    });
  }

  downloadFile(arquivo: Arquivo) {
    this.auditoriaTesteControleConstatacaoService.getAuditoriaArquivo(
      arquivo.id
    ).subscribe(
      (retorno) => {
        importedSaveAs(retorno, arquivo.nome)
      },
      (erro) => {
      }
    )
  }
}
