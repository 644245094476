import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { OverlayService } from 'src/app/core/services/overlay.service';
import { Ano, AnoService } from 'src/app/services/ano.service';
import { AuditoriaTesteControle, AuditoriaTesteControleService } from 'src/app/services/auditoria-teste-controle.service';

@Component({
  selector: 'app-replica-form',
  templateUrl: './replica-form.page.html',
  styleUrls: ['./replica-form.page.scss'],
})
export class ReplicaFormPage implements OnInit {

  anosOrigem: Ano[] = [];
  anoOrigem: Ano = {} as Ano;

  anosDestino: Ano[] = [];
  anoDestino: Ano = {} as Ano;

  testeControleList: AuditoriaTesteControle[] = [];

  constructor(
    private modalController: ModalController,
    private router: Router,
    private auditoriaTesteControleService: AuditoriaTesteControleService,
    private anoService: AnoService,   
    private overlayService: OverlayService 
  ) { }

  toggleSelectAll() {
    const allSelected = this.testeControleList.every(item => item['replicar']); // Verifica se todos estão selecionados

    // Se todos estiverem selecionados, desmarque, senão marque todos
    this.testeControleList.forEach(item => item['replicar'] = !allSelected);
  }

  async closeModal() {
    await this.modalController.dismiss();
  }

  private async alertaCamposObrigatorios(msg: string[] = []) {
    let html = '';
    msg.forEach((ms) => {
      html += '<li> '.concat(ms).concat('</li>');
    });

    const alert = await this.overlayService.alert({
      header: 'Seleção inválida.',
      message: html,
      buttons: ['OK']
    });

    await alert.present();
  }

  validaCampos(){   
    var result:boolean = false; 
    var messages:string[] = [];
    // Valida se os campos 'anoOrigem' e 'anoDestino' estão preenchidos
    if (!this.anoOrigem || !this.anoOrigem.id) {
      messages.push('Ano de Origem é obrigatório');      
    }

    if (!this.anoDestino || !this.anoDestino.id) {
      messages.push('Ano de Destino é obrigatório');      
    }

    // Verifica se anoOrigem e anoDestino são iguais
    if (this.anoOrigem.id === this.anoDestino.id) {
      messages.push('Ano de Origem e Ano de Destino não podem ser iguais');
    }

    // Valida se ao menos um item da lista 'testeControleList' tem 'replicar' marcado
    const hasReplicarSelected = this.testeControleList.some(item => item['replicar']);
    if (!hasReplicarSelected) {
      messages.push('Pelo menos um item deve ser marcado para replicação');      
    }
    if (messages.length){
      this.alertaCamposObrigatorios(messages);
    }else{
      result = true;
    }
    return result;
  }

  async confirmModal() {
    if (this.validaCampos()){
      const idsReplicar = this.testeControleList
      .filter(item => item['replicar']) // Filtra os itens que têm 'replicar' igual a true
      .map(item => item.id); // Cria uma lista apenas com os IDs desses itens

      // Cria o objeto que será enviado para o backend
      const params = {
        testeControleIds: idsReplicar,      // Lista com os IDs
        anoDestinoId: this.anoDestino.id // Inclui o anoDestino.id
      };
      await this.auditoriaTesteControleService.replicaTesteControle(params);
        this.modalController.dismiss(
          this.testeControleList
        );      
    };   
  }

  ngOnInit() {}

  ionViewDidEnter() {
    this.carregarDados();
  }

  async carregarDados() {
    try {
      // Sincroniza e carrega os registros de auditoria
      

      // Carrega todos os anos e cria um mapa para consulta eficiente
      const anos = await this.anoService.getAllAno();      
      this.anosOrigem = anos.sort((a, b) => (a.ano as any) - (b.ano as any))      
      this.anosDestino = anos.sort((a, b) => (a.ano as any) - (b.ano as any))      
      
    
    } catch (error) {
      console.error("Erro ao carregar dados:", error);
    }
  }

  onAnoOrigemChange(event: any): void {    
    this.auditoriaTesteControleService.getAllAuditoriaTesteControle().then(
      (registros: AuditoriaTesteControle[]) => {           
        this.testeControleList = registros.filter(
          (registro) => registro.ano_id === this.anoOrigem.id
        );
      });
  }

}
