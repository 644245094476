import { Injectable } from "@angular/core";
import { Storage } from "@ionic/storage";
import { ApiService } from "../api/api.service";

const TABELA = "fiscalizacao_processo";

export interface FiscalizacaoProcesso {
  id: number;
  descricao: string;
}

@Injectable({
  providedIn: "root",
})
export class FiscalizacaoProcessoService {
  constructor(private storage: Storage, private apiService: ApiService) {}

  sincronizaFiscalizacaoProcesso() {
    return this.apiService.syncData("sincroniza_fiscalizacao_processo").subscribe(
      (retorno) => {
        if (retorno.length > 0) {
          this.addFiscalizacaoProcessoSync(retorno);
        }
      },
      (erro) => {}
    );
  }

  getFiscalizacaoProcessoById(id): Promise<FiscalizacaoProcesso> {
    return this.storage.get(TABELA).then((registros) => {
      return registros.find((registro) => registro.id === parseInt(id));
    });
  }

  getAllFiscalizacaoProcesso(): Promise<FiscalizacaoProcesso[]> {
    return this.storage.get(TABELA);
  }

  async addFiscalizacaoProcessoSync(registros: FiscalizacaoProcesso[]): Promise<any> {
    return this.storage.set(TABELA, registros);
  }
}
