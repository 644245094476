import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { AuditoriaTesteSubstantivoConstatacaoEvidenciaSubstantiva } from 'src/app/services/auditoria/teste-substantivo-constatacao-evidencia-substantiva.service';

@Component({
  selector: 'app-edita-registros',
  templateUrl: './edita-registros.page.html',
  styleUrls: ['./edita-registros.page.scss'],
})
export class EditaRegistrosPage implements OnInit {
  auditoria_evidencia_substantiva: AuditoriaTesteSubstantivoConstatacaoEvidenciaSubstantiva = {} as AuditoriaTesteSubstantivoConstatacaoEvidenciaSubstantiva;
  solicitacaoId: number = null;
  solicitacaoDescricao: string = null;

  constructor(
    private modalController: ModalController,
    private params: NavParams,
  ) { }

  ngOnInit() {
    this.auditoria_evidencia_substantiva = this.params.data.registro;
    this.solicitacaoDescricao = this.params.data.descricao_solicitacao;
    this.solicitacaoId = this.params.data.solicitacao_informacao_id;
  }

  async closeModal() {
    await this.modalController.dismiss();
  }

  async confirmModal() {
    await this.modalController.dismiss(
      this.auditoria_evidencia_substantiva
    );
  }

  setFileAnexo(ev) {
    this.auditoria_evidencia_substantiva.fileAnexo = ev.target.files;
  }

  setValStatus(ev) {
    if (ev.detail.checked) {
      this.auditoria_evidencia_substantiva.status = "T";
    }
  }


  alterStyleModal(checked: boolean) {
    let modalInformacoes = document.body.querySelector('#modal_edicao_es');
    if (checked) {
      modalInformacoes.classList.add('modal-solicitacao-informacao-teste-controle-full-screen');
    } else {
      modalInformacoes.classList.remove('modal-solicitacao-informacao-teste-controle-full-screen');
    }
  }

}
