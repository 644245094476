import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { OverlayService } from '../core/services/overlay.service';

export interface Setor {
  id: number;
  nome: string;
}

@Injectable({
  providedIn: 'root'
})
export class SetorService {

  constructor(
    private apiService: ApiService,
    private overlayService: OverlayService
  ) { }

  async getAllSetor(): Promise<Setor[]> {
    return new Promise((resolve, reject) => {
      this.apiService.syncData('sincroniza_setor').subscribe(
        (retorno: Setor[]) => {
          resolve(retorno);
        },
        (erro) => {
          let msg = '';
          if (erro.error != undefined) {
            msg = erro.error.message
          } else {
            msg = erro.message
          }
          this.overlayService.toast(
            {
              message: msg,
              color: 'danger'
            }
          )
          reject();
        }
      )
    });
  }
}
