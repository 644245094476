import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController, ModalController } from '@ionic/angular';
import { TermoUso, TermoUsoService } from 'src/app/services/informacao/termo-uso.service';
import { Login, LoginService } from 'src/app/services/login.service';
import { Usuario, UsuarioService } from 'src/app/services/usuario.service';

@Component({
  selector: 'app-termo-uso',
  templateUrl: './termo-uso.page.html',
  styleUrls: ['./termo-uso.page.scss'],
})
export class TermoUsoPage implements OnInit {

  termoUso: TermoUso = {} as TermoUso;
  usuario: Usuario = {} as Usuario;

  constructor(
    private termoUsoService: TermoUsoService,
    private loginService: LoginService,
    private usuarioService: UsuarioService,
    private modalController: ModalController,
    private menuController: MenuController,
    private router: Router
  ) { }

  async ngOnInit() {
    await this.termoUsoService.getTermoUsoAtivo().then(
      (registro: TermoUso) => this.termoUso = registro != null ? registro : this.termoUso
    );
    await this.loginService.getLogin().then(
      (login: Login) => {
        this.usuarioService.getUsuarioById(login.conta.usuario_id).then(
          (usuario: Usuario) => this.usuario = usuario
        )
      }
    )
  }

  setRejeitaTermoUso() {
    this.destroyLogin();
    this.router.navigate(["login"])
    this.modalController.dismiss();
  }

  async setAceitaTermoUso(ev): Promise<void> {
    this.usuario["termo_uso_aceito"] = true;
    await this.usuarioService.envioDadosUsuario(
      this.usuario,
      false
    );
    this.loginService.setTermoUso(true);
    this.modalController.dismiss();
  }

  destroyLogin() {
    this.menuController.enable(false, 'menu_principal');
    this.menuController.close('menu_principal');
    this.loginService.destroyLogin();
  }

}
