import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { LoadingController, ToastController } from '@ionic/angular';
import { ApiService } from '../api/api.service';
import { Router } from '@angular/router';

export interface Equacao {
  id: number;
  ano_id: string;
  indicador_id: string;
  dado_id: string;
  equacao: string;
}

const TABELA = "equacao"

@Injectable({
  providedIn: 'root'
})
export class EquacaoService {
  loading: any;

  constructor(
    private storage: Storage,
    private apiService: ApiService,
    private toastController: ToastController,
    public loadingController: LoadingController,
    private router: Router,
  ) { }

  getEquacaoById(id) {
    return this.storage.get(TABELA).then(
      (equacoes) => {
        return equacoes.find(
          (equacao) => equacao.id === parseInt(id)
        )
      }
    );
  }

  getAllEquacao(): Promise<Equacao[]> {
    return this.storage.get(TABELA);
  }

  envioDadosEquacao(equacao2: Equacao): any {    
    this.apiService.sendData(
      equacao2,
      "set_equacao"
    ).subscribe(
      (retorno) => {
        this.showMensage("Registro inserido/alterado com sucesso.", 'success');
        this.setRetornoApi(equacao2, retorno);
        this.router.navigate(['equacao'], { queryParams: { id: retorno.id }, skipLocationChange: true });
      },
      (erro) => {
        let msg = '';
        if (erro.error != undefined) {
          msg = erro.error.message
        } else {
          msg = erro.message
        }
        this.showMensage(msg, 'danger')
        return false;
      }
    );
  }

  realizaClone(params: {}) {
    this.apiService.sendData(
      params,
      "clona_equacao"
    ).subscribe(
      (retorno) => {
        this.showMensage("Equações clonadas com sucesso.", 'success');
        this.sincronizaEquacao();
        this.router.navigate(['equacao'], { skipLocationChange: true });
      },
      (erro) => {
        let msg = '';
        if (erro.error != undefined) {
          msg = erro.error.message
        } else {
          msg = erro.message
        }
        this.showMensage(msg, 'danger')
        return false;
      }
    );
  }

  sincronizaEquacao() {
    this.showLoading("Sincronizando equação...").then(
      () => {
        return this.apiService.syncData('sincroniza_equacao').subscribe(
          (retorno) => {
            if (retorno.length > 0) {
              this.addEquacaoSync(retorno);
            }
            this.loadingController.dismiss('sync_equacao');
          },
          (erro) => {
            let msg = '';
            if (erro.error != undefined) {
              msg = erro.error.message
            } else {
              msg = erro.message
            }
            this.showMensage("Erro ao sincronizar equação. :" + msg, 'danger');
            this.loadingController.dismiss('sync_equacao');
          }
        );
      }
    );
  }

  async addEquacaoSync(equacoes: Equacao[]): Promise<any> {
    return this.storage.set(TABELA, equacoes);
  }


  private async addEquacao(equacao: Equacao): Promise<any> {
    return this.storage.get(TABELA).then((equacoes: Equacao[]) => {
      if (equacoes) {
        equacoes.push(equacao);
        this.storage.set(TABELA, equacoes);
      } else {
        this.storage.set(TABELA, [equacao]);
      }
    });
  }

  private updateEquacao(equacao: Equacao): Promise<any> {
    return this.storage.get(TABELA).then((equacoes: Equacao[]) => {
      if (!equacoes || equacoes.length === 0) {
        return null;
      }

      let newEquacoes: Equacao[] = [];

      for (let i of equacoes) {
        if (i.id === equacao.id) {
          newEquacoes.push(equacao);
        } else {
          newEquacoes.push(i);
        }
      }

      return this.storage.set(TABELA, newEquacoes);
    });
  }

  private setRetornoApi(equacao: Equacao, retorno) {
    if (equacao.id != null && equacao.id != undefined) {
      this.updateEquacao(equacao);
    }
    else {
      equacao.id = retorno.id;
      this.addEquacao(equacao);
    }

  }

  private async showMensage(msg, color) {
    const toast = await this.toastController.create({
      message: msg,
      color: color,
      duration: 10000
    });
    toast.present();
  }

  private async showLoading(msg: string) {
    await this.loadingController.create({
      message: msg,
      spinner: 'crescent',
      id: 'sync_equacao'
    }).then(
      (v) => {
        this.loading = v;
        v.present();
      }
    );

  }
}
