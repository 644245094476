import { Injectable } from '@angular/core';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable'
import { formatDate } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class GeraTablePdfService {

  constructor() { }

  geraArquivoPdf(cabecalho, head, body, nome_arquivo) {
    let data_atual = new Date();
    const doc = new jsPDF('l', 'mm', 'a4');
    autoTable(doc, {
      theme: 'plain',
      body: cabecalho
    })
    autoTable(doc, {
      head: head,
      body: body,
      theme: 'grid',
      headStyles: { fillColor: [227, 227, 227], textColor: [30, 107, 184], halign: "center", valign: 'middle', lineWidth: 0.1, lineColor: 0 },
      footStyles: { fillColor: [227, 227, 227], textColor: [30, 107, 184], halign: "right", lineWidth: 0.1, lineColor: 0 },
      bodyStyles: { lineColor: 0, lineWidth: 0.1 },
      foot: [[{ content: "Data/Hora impressão: " + formatDate(data_atual, 'dd/MM/y HH:mm:ss', 'pt-pt'), colSpan: head[0].length, styles: { halign: 'right' } }]]
    })
    doc.save(nome_arquivo);
  }

}
