import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { Chart } from "chart.js";
import { OverlayService } from 'src/app/core/services/overlay.service';
import { FiscalizacaoItemTesteFiscalizacaoConstatacao } from 'src/app/services/fiscalizacao-teste-fiscalizacao-constatacao.service';

@Component({
  selector: 'app-modal-grafico-solicitacao-informacao',
  templateUrl: './modal-grafico-solicitacao-informacao.page.html',
  styleUrls: ['./modal-grafico-solicitacao-informacao.page.scss'],
})
export class ModalGraficoSolicitacaoInformacaoPage implements OnInit {

  registros: any[] = [];
  labels: string[] = [
    'Solicitado pelo auditor',
    'Em execução',
    'Finalizado para fiscalizacao',
    'Não se aplica',
    'Dados insuficientes',
    'Dados entregues'
  ];
  data: number[] = [];
  backgroundColor: string[] = [
    'rgb(255, 107, 102)',
    'rgb(255, 255, 102)',
    'rgb(10, 191, 46)',
    'rgb(196, 196, 196)',
    'rgb(255, 51, 0)',
    'rgb(10, 191, 46)'
  ];

  chartSolicitacao: any;
  @ViewChild("dashboardSolicitacao") dashboardSolicitacao;
  constructor(
    private modalController: ModalController,
    private params: NavParams,
    private overlayService: OverlayService
  ) { }

  async closeModal() {
    await this.modalController.dismiss();
  }

  ngOnInit() {
  }

  ionViewDidEnter() {
    this.registros = this.params.data.registros;
    this.buildChartSolicitacoes();
  }

  private async processaDadosGrafico(): Promise<void> {
    const loading = await this.overlayService.loading({ message: "Carregando gráfico..." });
    return new Promise(async (resolve) => {
      let solicitadoAuditor = 0;
      let emExecucao = 0;
      let finalizadoParaFiscalizacao = 0;
      let naoSeAplica = 0;
      let dadosInsuficientes = 0;
      let dadosEntregues = 0;
      let blackListId = [];
      for await (const reg of this.registros) {
        if (reg["solicitacoes_informacoes"] != null) {
          for await (const solicitacao of reg["solicitacoes_informacoes"]) {
            if (blackListId.indexOf(solicitacao["id"]) == -1) {
              if (solicitacao["resolicitacao_auditor_id"] != null) {
                solicitadoAuditor += 1;
              } else if (solicitacao["status_desc"] == 'Em execução') {
                emExecucao += 1;
              } else if (solicitacao["status_desc"] == 'Finalizado para fiscalizacao') {
                finalizadoParaFiscalizacao += 1;
              } else if (solicitacao["status_desc"] == 'Não se aplica') {
                naoSeAplica += 1;
              } else if (solicitacao["status_desc"] == 'Dados insuficientes') {
                dadosInsuficientes += 1;
              } else if (solicitacao["status_desc"] == 'Dados entregues') {
                dadosEntregues += 1;
              }
              blackListId.push(solicitacao["id"]);
            }
          }
        }
      };
      this.data = [
        solicitadoAuditor,
        emExecucao,
        finalizadoParaFiscalizacao,
        naoSeAplica,
        dadosInsuficientes,
        dadosEntregues
      ];
      loading.dismiss();
      resolve();
    })
  }

  private async buildChartSolicitacoes(): Promise<void> {
    let element = this.dashboardSolicitacao.nativeElement;
    if (this.registros != null) {
      await this.processaDadosGrafico();
    }
    if (this.chartSolicitacao != undefined) {
      this.chartSolicitacao.destroy();
    }
    this.chartSolicitacao = new Chart(element, {
      type: "pie",
      data: {
        labels: this.labels,
        datasets: [
          {
            data: this.data,
            backgroundColor: this.backgroundColor,
          },
        ],
      }
    });
  }

}
