import { Component, OnInit } from "@angular/core";
import { ModalController, NavParams } from "@ionic/angular";
import { AgenciaReguladora, AgenciaReguladoraService } from "src/app/services/agencia-reguladora.service";
import { Ano, AnoService, AnoTemp, MesTemp } from "src/app/services/ano.service";
import { Municipio, MunicipioService, } from "src/app/services/municipio.service";
import { Prestador, PrestadorService, } from "src/app/services/prestador.service";

@Component({
  selector: "app-filtra-registros",
  templateUrl: "./filtra-registros.page.html",
  styleUrls: ["./filtra-registros.page.scss"],
})
export class FiltraRegistrosPage implements OnInit {
  agencias: AgenciaReguladora[] = [];
  agencia_reguladora: AgenciaReguladora = {} as AgenciaReguladora;

  municipios: Municipio[] = [];
  municipio: Municipio = {} as Municipio;

  prestadores: Prestador[] = [];
  prestador: Prestador = {} as Prestador;

  ano: Ano = {} as Ano;
  anos: Ano[] = [];


  mes_p: MesTemp = {} as MesTemp;
  meses_p: MesTemp[] = [{id: 1, descricao: 'Janeiro'},
                  {id: 2, descricao: 'Fevereiro'},
                  {id: 3, descricao: 'Março'},
                  {id: 4, descricao: 'Abril'},
                  {id: 5, descricao: 'Maio'},
                  {id: 6, descricao: 'Junho'},
                  {id: 7, descricao: 'Julho'},
                  {id: 8, descricao: 'Agosto'},
                  {id: 9, descricao: 'Setembro'},
                  {id: 10, descricao: 'Outubro'},
                  {id: 11, descricao: 'Novembro'},
                  {id: 12, descricao: 'Dezembro'}];

  quantidade_registro: number = 15;


  constructor(
    private modalController: ModalController,
    private params: NavParams,
    private agenciaReguladoraService: AgenciaReguladoraService,
    private municipioService: MunicipioService,
    private prestadorService: PrestadorService,
    private anoService: AnoService
  ) { }

  filterPrestador(ev) {
    this.prestadorService.getAllPrestadorUsuario(this.params.data.usuario_id, ev.value.id).then(
      (prestadores: Prestador[]) => {
        this.prestadores = prestadores;
      }
    );
    this.prestador = undefined;
  }

  filterMunicipio(ev) {
    this.municipioService.getAllMunicipioUsuario(this.params.data.usuario_id, ev.value.id).then(
      (municipios: Municipio[]) => {
        this.municipios = municipios;
      }
    );
    this.municipio = undefined;
    this.prestador = undefined;
  }

  ngOnInit() {    

    this.mes_p = this.meses_p.filter(
      (el: MesTemp) => {
        return (el.id === this.params.data.mes_p)
      }
    )[0];
    
    
    this.quantidade_registro = this.params.data.quantidade_registro;
    
    this.anoService.getAllAno().then(
      (anos: Ano[]) => {
        this.anos = anos.sort((a, b) => (a.ano as any) - (b.ano as any))
      }
    );
    if (this.params.data.ano_id != undefined) {
      this.anoService.getAnoById(this.params.data.ano_id).then(
        (ano: Ano) => {
          this.ano = ano;
        }
      );
    } else {
      this.ano = undefined;
    }

    this.municipioService.getAllMunicipioUsuario(this.params.data.usuario_id, undefined).then(
      (municipios: Municipio[]) => {              
        this.municipios = municipios;
      });  
    
    
  }

  async closeModal() {
    await this.modalController.dismiss();
  }

  async confirmModal() {
    let retorno = {};       
    if (this.municipio != undefined) {
      retorno["municipio_id"] = this.municipio.id;
    } else {
      retorno["municipio_id"] = undefined;
    }
    if (this.prestador != undefined) {
      retorno["prestador_id"] = this.prestador.id;
    } else {
      retorno["prestador_id"] = undefined;
    }
    if (this.ano != undefined) {
      retorno["ano_id"] = this.ano.id;
    } else {
      retorno["ano_id"] = undefined;
    }
    retorno["quantidade_registro"] = this.quantidade_registro;
    retorno["mes_p_id"] = this.mes_p.id;
    retorno["ano_p_id"] = this.ano.ano;
    await this.modalController.dismiss(retorno);
  }
}
