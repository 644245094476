import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-field-certificacao',
  templateUrl: './field-certificacao.component.html',
  styleUrls: ['./field-certificacao.component.scss'],
})
export class FieldCertificacaoComponent implements OnInit {
  @Input() rowData: any;

  color: string;
  fontColor: string;
  certificacao: string;

  constructor() { }

  ngOnInit() {
    if (this.rowData.color != null) {
      this.color = this.rowData.color;
      this.fontColor = '#ffffff';
    } else {
      this.color = '#ededed';
      this.fontColor = '#000000';
    }
    this.certificacao = this.rowData.certificacao;
  }

}
