import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { OverlayService } from 'src/app/core/services/overlay.service';
import { AuditoriaProcesso, AuditoriaProcessoService } from 'src/app/services/auditoria-processo.service';
import { CtsProcessoAuditoria, ProcessoAuditoriaService } from 'src/app/services/auditoria/processo-auditoria.service';

@Component({
  selector: 'app-modal-seta-teste-controle',
  templateUrl: './modal-seta-teste-controle.page.html',
  styleUrls: ['./modal-seta-teste-controle.page.scss'],
})
export class ModalSetaTesteControlePage implements OnInit {

  testeControleList: CtsProcessoAuditoria[] = [];

  ciclo: String = undefined;

  auditoriaProcesso: AuditoriaProcesso = {} as AuditoriaProcesso;
  procesos: AuditoriaProcesso[] = [];

  constructor(
    private modalController: ModalController,
    private processoAuditoriaService: ProcessoAuditoriaService,
    private auditoriaProcessoService: AuditoriaProcessoService,
    private params: NavParams,
    private overlayService: OverlayService,
  ) { }

  selecionaTodos(ev): void {
    let valor = ev.detail.checked;
    this.testeControleList.forEach(
      (el: CtsProcessoAuditoria) => {
        el.selecionado = valor;
      }
    );
  }

  ngOnInit() {
    this.processoAuditoriaService.getCTSProcessoAuditoria(this.params.data.processoAuditoriaId).then(
      (regs: CtsProcessoAuditoria[]) => {
        this.testeControleList = regs;
      }
    );

    this.auditoriaProcesso = undefined;
    this.auditoriaProcessoService.getAllAuditoriaProcesso().then(
      (registros: AuditoriaProcesso[]) => {
        this.procesos = registros;
      }
    )
  }

  async closeModal(): Promise<void> {
    await this.modalController.dismiss();
  }

  async confirmModal(): Promise<void> {
    const loading = await this.overlayService.loading({ message: "Salvando..." });
    await this.processoAuditoriaService.setCtsProcessoAuditoria(
      this.params.data.processoAuditoriaId,
      this.testeControleList
    ).then(
      () => {
        loading.dismiss();
        this.modalController.dismiss();
      }
    )
  }

}
