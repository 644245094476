import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { Imagem, ImagensService } from 'src/app/services/configuracao/imagens.service';

@Component({
  selector: 'app-imagens-form',
  templateUrl: './imagens-form.page.html',
  styleUrls: ['./imagens-form.page.scss'],
})
export class ImagensFormPage implements OnInit {

  imagem: Imagem = {} as Imagem;

  constructor(
    private router: Router,
    private imagensService: ImagensService,
    private route: ActivatedRoute,
    private alertController: AlertController,
  ) { }

  ngOnInit() {
    if ((this.route.snapshot.queryParams.id !== null) && (typeof this.route.snapshot.queryParams.id !== 'undefined')) {
      this.imagensService.getImagensById(this.route.snapshot.queryParams.id).then(
        (imagem: Imagem) => {
          this.imagem = imagem;
        }
      );
    }
  }

  pushHome() {
    this.router.navigate(['home'], { skipLocationChange: true })
  }

  finalizaCadastro() {    
    /*var name = this.imagem.arquivo;
    if (name != undefined){
      this.imagem.nome = name.split('\\')[name.split('\\').length-1];
    };*/

    let msg: string[] = [];

    msg = this.validaCampos();

    if (msg.length === 0) {
      this.salvaDado();
    } else {
      this.alertaCamposObrigatorios(msg);
    }
  }

  private validaCampos() {
    var msg: string[] = [];
    if ((this.imagem.arquivo === '') || (typeof this.imagem.arquivo === 'undefined')) {
      msg.push('Arquivo.');
    }

    return msg;
  }

  private async alertaCamposObrigatorios(msg: string[] = []) {
    let html = '<p>Preenchimento obrigatório dos campos:</p>';
    msg.forEach((ms) => {
      html += '<li> '.concat(ms).concat('</li>');
    });

    const alert = await this.alertController.create({
      header: 'Cadastro inválido.',
      message: html,
      buttons: ['OK']
    });

    await alert.present();
  }

  private salvaDado() {    
    this.imagensService.envioDadosImagem(this.imagem)
  }

  loadImageFromDevice(event) {

    this.imagem.nome = event.target.files[0].name;
    const file = event.target.files[0];
  
    this.imagem.arquivo = file;

    
  };

}
