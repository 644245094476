import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, ModalController, NavParams } from '@ionic/angular';
import { OverlayService } from 'src/app/core/services/overlay.service';
import { Login, LoginService } from 'src/app/services/login.service';
import { TipoAtivo, TipoAtivoService } from 'src/app/services/tipo-ativo.service';
import { TesteFiscalizacaoPage } from './teste-fiscalizacao/teste-fiscalizacao.page';
import { TesteFiscalizacao } from 'src/app/services/teste-fiscalizacao.service';

@Component({
  selector: 'app-tipo-ativo-form',
  templateUrl: './tipo-ativo-form.page.html',
  styleUrls: ['./tipo-ativo-form.page.scss'],
})
export class TipoAtivoFormPage implements OnInit {

  tipoAtivo: TipoAtivo = {} as TipoAtivo;
  testeFiscalizacoes: TesteFiscalizacao[] = [];  

  usuario_id: number;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private alertController: AlertController,    
    private loginService: LoginService,
    private tipoAtivoService: TipoAtivoService,
    private overlayService: OverlayService, 
    private modalController: ModalController,

  ) { }

  async ngOnInit() {
    await this.loginService.getLogin().then(
      async (login: Login) => {
        this.usuario_id = login.conta.usuario_id;       
      }
    ).then(
      () => {
        this.carregaDados();
      }
    );
  }
  async carregaDados(){
    if ((this.route.snapshot.queryParams.id !== null) && (typeof this.route.snapshot.queryParams.id !== 'undefined')) {
      await this.tipoAtivoService.getTipoAtivoById(this.route.snapshot.queryParams.id).then(
        (tipoAtivo: TipoAtivo) => {
          this.tipoAtivo = tipoAtivo;          
          if (this.tipoAtivo.testefiscalizacoes === null) {
            this.testeFiscalizacoes = [];
          } else {
            this.testeFiscalizacoes= this.tipoAtivo.testefiscalizacoes;
          };
        }
      );
    }  
  }

  finalizaCadastro() {
    let msg: string[] = [];    
    msg = this.validaCampos();

    if (msg.length === 0) {
      this.salvaDado();
    } else {
      this.alertaCamposObrigatorios(msg);
    }
  }

  private validaCampos() {
    var msg: string[] = [];

    if ((this.tipoAtivo.descricao === '') || (typeof this.tipoAtivo.descricao === 'undefined')) {
      msg.push('Descrição.');
    }

    if ((this.tipoAtivo.sigla === '') || (typeof this.tipoAtivo.sigla === 'undefined')) {
      msg.push('Sigla.');
    }

    return msg;
  }


  private async alertaCamposObrigatorios(msg: string[] = []) {
    let html = '<p>Preenchimento obrigatório dos campos:</p>';
    msg.forEach((ms) => {
      html += '<li> '.concat(ms).concat('</li>');
    });

    const alert = await this.alertController.create({
      header: 'Cadastro inválido.',
      message: html,
      buttons: ['OK']
    });

    await alert.present();
  }

  private salvaDado() {
    this.tipoAtivo.testefiscalizacoes = this.testeFiscalizacoes;
    this.tipoAtivoService.envioDadosTipoAtivo(this.tipoAtivo)
  }

  async addTesteFiscalizacao(): Promise<void> {
    const modal = await this.modalController.create({
      component: TesteFiscalizacaoPage
    });

    modal.onDidDismiss().then(async (params) => {
      if (params !== null) {
        if (params.data != undefined) {
          this.testeFiscalizacoes.push(
            params.data
          );
        }
      }
    });
    return await modal.present();
  }

  async editaTesteFiscalizacao(atributo): Promise<void> {
    const modal = await this.modalController.create({
      component: TesteFiscalizacaoPage,
      componentProps: {
        atributo_id: atributo.id
      },
    });

    modal.onDidDismiss().then(async (params) => {
      if (params !== null) {
        if (params.data != undefined) {
          let indexTesteFiscalizacao = this.testeFiscalizacoes.findIndex(el => el.id == atributo.id);
          this.testeFiscalizacoes[indexTesteFiscalizacao] = params.data;
        }
      }
    });
    return await modal.present();
  }

}
