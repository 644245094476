import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { OverlayService } from 'src/app/core/services/overlay.service';
import { FiscalizacaoTesteFiscalizacaoConstatacaoSolicitacaoInformacao, TesteFiscalizacaoConstatacaoSolicitacaoInformacaoService } from 'src/app/services/fiscalizacao/teste-fiscalizacao-constatacao-solicitacao-informacao.service';

@Component({
  selector: 'app-edita-registros',
  templateUrl: './edita-registros.page.html',
  styleUrls: ['./edita-registros.page.scss'],
})
export class EditaRegistrosPage implements OnInit {

  fiscalizacao_solicitacao_informacao: FiscalizacaoTesteFiscalizacaoConstatacaoSolicitacaoInformacao = {} as FiscalizacaoTesteFiscalizacaoConstatacaoSolicitacaoInformacao;
  resolicitacaoId: number = null;
  resolicitacaoDescricao: string = null;
  anobase: string = undefined;
  anoList:number[] = [];
  indano: boolean = false;
  base_importacao_id: number;

  constructor(
    private modalController: ModalController,
    private params: NavParams,
    private testeFiscalizacaoConstatacaoSolicitacaoInformacaoService: TesteFiscalizacaoConstatacaoSolicitacaoInformacaoService,
    private overlayService: OverlayService,
  ) { }

  btnImportar(){
    if (this.anobase != undefined){
      this.testeFiscalizacaoConstatacaoSolicitacaoInformacaoService.importar(this.fiscalizacao_solicitacao_informacao,this.anobase).then((retorno:any) => {
        this.fiscalizacao_solicitacao_informacao.inexistente = retorno.inexistente;
        this.fiscalizacao_solicitacao_informacao.observacao = retorno.observacao;
        this.fiscalizacao_solicitacao_informacao.status = retorno.status;
        this.fiscalizacao_solicitacao_informacao.base_importacao_id = retorno.base_importacao_id;
      })
    }else{
      this.overlayService.toast(
        {
          message: "Selecione um ano para importar.",
          color: 'danger'
        }
      )
    };   
  }

  async closeModal() {
    await this.modalController.dismiss();
  }

  setFileAnexo(ev) {
    this.fiscalizacao_solicitacao_informacao.fileAnexo = ev.target.files;
  }

  async confirmModal() {
    await this.modalController.dismiss(
      this.fiscalizacao_solicitacao_informacao
    );
  }

  setValStatus(ev) {
    if (ev.detail.checked) {
      this.fiscalizacao_solicitacao_informacao.status = "T";
    }
  }


  ngOnInit() {
    this.fiscalizacao_solicitacao_informacao = this.params.data.registro;
    this.resolicitacaoDescricao = this.params.data.descricao_resolicitacao;
    this.resolicitacaoId = this.params.data.resolicitacao_informacao_id;
    
    
    this.testeFiscalizacaoConstatacaoSolicitacaoInformacaoService.consultar(this.fiscalizacao_solicitacao_informacao).then((retorno:any) => {
      if ( Object.keys(retorno).length > 0){  
        this.indano = true;      
        var anos:number[] = [];              
        this.anobase = retorno[0]['descricao'];          
        for (var i=0; i < Object.keys(retorno).length; i++){   
          anos.push(retorno[i]['descricao']);
        };
        this.anoList = anos;
      };      
    })
  }

  alterStyleModal(checked: boolean) {
    let modalInformacoes = document.body.querySelector('#modal_edicao_si');
    if (checked) {
      modalInformacoes.classList.add('modal-solicitacao-informacao-teste-fiscalizacao-full-screen');
    } else {
      modalInformacoes.classList.remove('modal-solicitacao-informacao-teste-fiscalizacao-full-screen');
    }
  }

}
