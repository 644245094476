import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { OverlayService } from 'src/app/core/services/overlay.service';
import { Atributo, AtributoService } from 'src/app/services/auditoria/atributo.service';

@Component({
  selector: 'app-atributo',
  templateUrl: './atributo.page.html',
  styleUrls: ['./atributo.page.scss'],
})
export class AtributoPage implements OnInit {

  acao: string = "insert";
  atributoDesc: string = "";
  observacao: string = "";
  atributo: Atributo = {} as Atributo;
  atributos: Atributo[] = [];

  constructor(
    private modalController: ModalController,
    private atributoService: AtributoService,
    private overlayService: OverlayService,
    private params: NavParams,
  ) { }

  async ngOnInit() {
    await this.atributoService.getAllAtributos().then(
      (atributos: Atributo[]) => {
        this.atributos = atributos;
      }
    );
    if (this.params.data.atributo_id != undefined) {
      this.atributo = this.atributos.find(
        (el: Atributo) => el.id === this.params.data.atributo_id
      )
      this.observacao = this.params.data.observacao;
      this.atributoDesc = this.atributo.descricao;
      this.acao = "edit";
    } else {
      this.atributoDesc = "";
      this.observacao = "";
      this.atributo = undefined;
      this.acao = "insert";
    }
  }

  async closeModal(): Promise<void> {
    await this.modalController.dismiss();
  }

  async confirmModal(): Promise<void> {
    let msg: string[] = [];
    msg = this.validaCampos();
    if (msg.length === 0) {
      let atributo_new: Atributo = {} as Atributo;
      if (this.acao == "insert") {
        atributo_new.descricao = this.atributoDesc;
        await this.atributoService.envioDadosAtributo(atributo_new).then(
          (atributo: Atributo) => {
            let retorno = {};
            retorno["id"] = atributo.id;
            retorno["descricao"] = atributo.descricao;
            retorno["observacao"] = this.observacao;
            this.modalController.dismiss(retorno);
          }
        );
      }
      else if (this.acao == "search") {
        let retorno = {};
        retorno["id"] = this.atributo.id;
        retorno["descricao"] = this.atributo.descricao;
        retorno["observacao"] = this.observacao;
        this.modalController.dismiss(retorno);
      }
      else if (this.acao == 'edit') {
        this.atributo.descricao = this.atributoDesc;
        await this.atributoService.envioDadosAtributo(this.atributo).then(
          (atributo: Atributo) => {
            let retorno = {};
            retorno["id"] = atributo.id;
            retorno["descricao"] = atributo.descricao;
            retorno["observacao"] = this.observacao;
            this.modalController.dismiss(retorno);
          }
        );
      }
    } else {
      this.alertaCamposObrigatorios(msg);
    }
  }

  private validaCampos(): string[] {
    var msg: string[] = [];

    if (this.acao == "insert" && (this.atributoDesc === '' || typeof this.atributoDesc === 'undefined')) {
      msg.push('Atributo.');
    }

    if (this.acao == "search" && (typeof this.atributo == 'undefined')) {
      msg.push('Atributo.');
    }

    return msg;
  }

  private async alertaCamposObrigatorios(msg: string[] = []) {
    let html = '<p>Preenchimento obrigatório dos campos:</p>';
    msg.forEach((ms) => {
      html += '<li> '.concat(ms).concat('</li>');
    });

    const alert = await this.overlayService.alert({
      header: 'Cadastro inválido.',
      message: html,
      buttons: ['OK']
    });

    await alert.present();
  }

}
