import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { TipoAtivoFormPage } from './tipo-ativo-form.page';

const routes: Routes = [
  {
    path: '',
    component: TipoAtivoFormPage
  },
  {
    path: 'teste-fiscalizacao',
    loadChildren: () => import('./teste-fiscalizacao/teste-fiscalizacao.module').then( m => m.TesteFiscalizacaoPageModule)
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TipoAtivoFormPageRoutingModule {}
