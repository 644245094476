import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { ApiService } from '../api/api.service'

export interface Uf {
  id: number;
  nome: string;
  sigla: string;
}

const TABELA = "uf"

@Injectable({
  providedIn: 'root'
})
export class UfService {

  constructor(
    private storage: Storage,
    private apiService: ApiService,
  ) { }

  sincronizaUf() {
    return this.apiService.syncData('sincroniza_uf').subscribe(
      (retorno) => {
        if (retorno.length > 0) {
          this.addUfSync(retorno);
        }
      },
      (erro) => {
      }
    );
  }

  getUfById(id): Promise<Uf> {
    return this.storage.get(TABELA).then(
      (ufs) => {
        return ufs.find(
          (uf) => uf.id === parseInt(id)
        )
      }
    );
  }

  getUfBySigla(sigla): Promise<Uf> {
    return this.storage.get(TABELA).then(
      (ufs) => {
        return ufs.find(
          (uf) => uf.sigla === sigla
        )
      }
    );
  }

  getAllUf(): Promise<Uf[]> {
    return this.storage.get(TABELA);
  }

  async addUfSync(ufs: Uf[]): Promise<any> {
    return this.storage.set(TABELA, ufs);
  }
}
