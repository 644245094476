import { Component, OnInit } from "@angular/core";
import { ModalController, NavParams } from "@ionic/angular";
import { AgenciaReguladora, AgenciaReguladoraService } from "src/app/services/agencia-reguladora.service";
import { Ano, AnoService } from "src/app/services/ano.service";
import { Municipio, MunicipioService, } from "src/app/services/municipio.service";
import { Prestador, PrestadorService, } from "src/app/services/prestador.service";

@Component({
  selector: "app-filtra-registros",
  templateUrl: "./filtra-registros.page.html",
  styleUrls: ["./filtra-registros.page.scss"],
})
export class FiltraRegistrosPage implements OnInit {
  agencias: AgenciaReguladora[] = [];
  agencia_reguladora: AgenciaReguladora = {} as AgenciaReguladora;

  municipios: Municipio[] = [];
  municipio: Municipio = {} as Municipio;

  prestadores: Prestador[] = [];
  prestador: Prestador = {} as Prestador;

  ano: Ano = {} as Ano;
  anos: Ano[] = [];

  quantidade_registro: number = 15;


  constructor(
    private modalController: ModalController,
    private params: NavParams,
    private agenciaReguladoraService: AgenciaReguladoraService,
    private municipioService: MunicipioService,
    private prestadorService: PrestadorService,
    private anoService: AnoService
  ) { }

  filterPrestador(ev) {
    this.prestadorService.getAllPrestadorUsuario(this.params.data.usuario_id, ev.value.id).then(
      (prestadores: Prestador[]) => {
        this.prestadores = prestadores;
      }
    );
    this.prestador = undefined;
  }

  filterMunicipio(ev) {
    this.municipioService.getAllMunicipioUsuario(this.params.data.usuario_id, ev.value.id).then(
      (municipios: Municipio[]) => {
        this.municipios = municipios;
      }
    );
    this.municipio = undefined;
    this.prestador = undefined;
  }

  ngOnInit() {
    this.quantidade_registro = this.params.data.quantidade_registro;
    this.agenciaReguladoraService.getAllAgenciaUsuario(this.params.data.usuario_id).then(
      (agencias: AgenciaReguladora[]) => {
        this.agencias = agencias;
      }
    ).then(
      () => {
        if (this.params.data.agencia_id != undefined) {
          this.municipioService.getAllMunicipioUsuario(this.params.data.usuario_id, this.params.data.agencia_id).then(
            (municipios: Municipio[]) => {
              this.municipios = municipios;
            }
          );
        } else {
          this.municipios = undefined
        }
        if (this.params.data.municipio_id != undefined) {
          this.prestadorService.getAllPrestadorUsuario(this.params.data.usuario_id, this.params.data.municipio_id).then(
            (prestadores: Prestador[]) => {
              this.prestadores = prestadores;
            }
          );
        } else {
          this.prestadores = undefined;
        }
        this.anoService.getAllAno().then(
          (anos: Ano[]) => {
            this.anos = anos.sort((a, b) => (a.ano as any) - (b.ano as any))
          }
        );
      }
    ).then(
      () => {
        if (this.params.data.agencia_id != undefined) {
          this.agenciaReguladoraService.getAgenciaById(this.params.data.agencia_id).then(
            (agencia: AgenciaReguladora) => {
              this.agencia_reguladora = agencia;
            }
          );
        } else {
          this.agencia_reguladora = undefined;
        }
        if (this.params.data.municipio_id != undefined) {
          this.municipioService.getMunicipioById(this.params.data.municipio_id).then(
            (municipio: Municipio) => {
              this.municipio = municipio;
            }
          );
        } else {
          this.municipio = undefined;
        }
        if (this.params.data.prestador_id != undefined) {
          this.prestadorService.getPrestadorById(this.params.data.prestador_id).then(
            (prestador: Prestador) => {
              this.prestador = prestador;
            }
          );
        } else {
          this.prestador = undefined;
        }
        if (this.params.data.ano_id != undefined) {
          this.anoService.getAnoById(this.params.data.ano_id).then(
            (ano: Ano) => {
              this.ano = ano;
            }
          );
        } else {
          this.ano = undefined;
        }
      }
    );
  }

  async closeModal() {
    await this.modalController.dismiss();
  }

  async confirmModal() {
    let retorno = {};
    if (this.agencia_reguladora != undefined) {
      retorno["agencia_id"] = this.agencia_reguladora.id;
    } else {
      retorno["agencia_id"] = undefined;
    }
    if (this.agencia_reguladora != undefined) {
      retorno["municipio_id"] = this.municipio.id;
    } else {
      retorno["municipio_id"] = undefined;
    }
    if (this.prestador != undefined) {
      retorno["prestador_id"] = this.prestador.id;
    } else {
      retorno["prestador_id"] = undefined;
    }
    if (this.ano != undefined) {
      retorno["ano_id"] = this.ano.id;
    } else {
      retorno["ano_id"] = undefined;
    }
    retorno["quantidade_registro"] = this.quantidade_registro;
    await this.modalController.dismiss(retorno);
  }
}
