import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/api/api.service';
import { OverlayService } from 'src/app/core/services/overlay.service';

export interface TermoUso {
  id: number;
  descricao: string;
  ativo: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class TermoUsoService {
  constructor(
    private apiService: ApiService,
    private overlayService: OverlayService
  ) { }

  async getTermoUsoAtivo(): Promise<TermoUso> {
    return new Promise((resolve, reject) => {
      this.apiService.syncData(
        'get_termo_uso'
      ).subscribe(
        (retorno: TermoUso) => {
          resolve(retorno);
        },
        (erro) => {
          let msg = '';
          if (erro.error != undefined) {
            msg = erro.error.message
          } else {
            msg = erro.message
          }
          this.overlayService.toast(
            {
              message: msg,
              color: 'danger'
            }
          )
          reject();
        }
      )
    });
  }

  async setTermoUso(termoUso: TermoUso): Promise<void> {
    return new Promise((resolve, reject) => {
      this.apiService.sendData(
        {
          id: termoUso.id,
          descricao: termoUso.descricao,
        },
        "set_termo_uso"
      ).subscribe(
        () => {
          resolve();
          this.overlayService.alert({
            message: "Termo de uso atualizado com sucesso.<br> Todos os usuários serão notificados.",
            buttons: [
              {
                text: "Ok"
              }
            ]
          });
        },
        (erro) => {
          let msg = '';
          if (erro.error != undefined) {
            msg = erro.error.message
          } else {
            msg = erro.message
          }
          this.overlayService.toast(
            {
              message: msg,
              color: 'danger'
            }
          )
          reject();
        }
      );
    });
  }
}
