import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { ApiService } from '../api/api.service'

const TABELA = "grupo_dado"

export interface GrupoDado {
  id: number;
  nome: string;
  ativo: string;
}

@Injectable({
  providedIn: 'root'
})
export class GrupoDadoService {

  constructor(
    private storage: Storage,
    private apiService: ApiService,
  ) { }

  sincronizaGrupoDado() {
    return this.apiService.syncData('sincroniza_grupo_dado').subscribe(
      (retorno) => {
        if (retorno.length > 0) {
          this.addGrupoDadoSync(retorno);
        }
      },
      (erro) => {
      }
    );
  }

  getGrupoDadoById(id): Promise<GrupoDado> {
    return this.storage.get(TABELA).then(
      (gruposDado) => {
        return gruposDado.find(
          (GrupoDado) => GrupoDado.id === parseInt(id)
        )
      }
    );
  }

  getAllGrupoDado(): Promise<GrupoDado[]> {
    return this.storage.get(TABELA);
  }


  async addGrupoDadoSync(gruposDado: GrupoDado[]): Promise<any> {
    return this.storage.set(TABELA, gruposDado);
  }
}
