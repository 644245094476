import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { ApiService } from '../api/api.service'

const TABELA = "municipio_ibge"

export interface MunicipioIbge {
  id: number;
  nome: string;
  sigla_uf: string;
  codigo_ibge: string;
}

@Injectable({
  providedIn: 'root'
})
export class MunicipioIbgeService {


  constructor(
    private storage: Storage,
    private apiService: ApiService,
  ) { }

  sincronizaMunicipio() {
    return this.apiService.syncData('sincroniza_municipio_ibge').subscribe(
      (retorno) => {
        if (retorno.length > 0) {
          this.addMunicipioSync(retorno);
        }
      },
      (erro) => {
      }
    );
  }

  getMunicipioById(id): Promise<MunicipioIbge> {
    return this.storage.get(TABELA).then(
      (municipios) => {
        return municipios.find(
          (municipio) => municipio.id === parseInt(id)
        )
      }
    );
  }

  getAllMunicipio(): Promise<MunicipioIbge[]> {
    return this.storage.get(TABELA);
  }


  async addMunicipioSync(municipios: MunicipioIbge[]): Promise<any> {
    return this.storage.set(TABELA, municipios);
  }

}
