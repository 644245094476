import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TermoUso, TermoUsoService } from 'src/app/services/informacao/termo-uso.service';
import { Usuario } from 'src/app/services/usuario.service';

@Component({
  selector: 'app-termo-uso',
  templateUrl: './termo-uso.page.html',
  styleUrls: ['./termo-uso.page.scss'],
})
export class TermoUsoPage implements OnInit {

  termoUso: TermoUso = {} as TermoUso;
  usuario: Usuario = {} as Usuario;

  constructor(
    private termoUsoService: TermoUsoService,
    private modalController: ModalController,
  ) { }

  async ngOnInit() {
    await this.termoUsoService.getTermoUsoAtivo().then(
      (registro: TermoUso) => this.termoUso = registro != null ? registro : this.termoUso
    );
  }

  closeModal() {
    this.modalController.dismiss();
  }

}
