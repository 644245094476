import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { DadoUsuario } from 'src/app/services/dado-municipio-prestador.service';
import { Dado, DadoService } from 'src/app/services/dado.service';

@Component({
  selector: 'app-edita-registros',
  templateUrl: './edita-registros.page.html',
  styleUrls: ['./edita-registros.page.scss'],
})
export class EditaRegistrosPage implements OnInit {

  dado: Dado = {} as Dado;
  dadoUsario: DadoUsuario = {} as DadoUsuario;

  val_jan: string;
 


  constructor(
    private modalController: ModalController,
    private params: NavParams,
    private dadoService: DadoService
  ) { }

  async closeModal() {
    await this.modalController.dismiss();
  }

  async confirmModal() {
    let newValue: JSON = {} as JSON;
    newValue[1] = this.val_jan;
    
    this.dadoUsario.valor = newValue
    await this.modalController.dismiss(
      this.dadoUsario
    );
  }

  ngOnInit() {
    this.dadoUsario = this.params.data.registro;
    this.dadoService.getDadoById(this.dadoUsario.dado_id).then(
      (dado: Dado) => {
        this.dado = dado;
      }
    ).then(
      () => {
        this.setVariaveis();
      }
    )
  }

  private setVariaveis() {
    this.val_jan = this.dadoUsario.valor[1];     
  }
}
