import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/api/api.service';
import { OverlayService } from 'src/app/core/services/overlay.service';
import { Arquivo } from '../auditoria-teste-controle-constatacao.service';

export interface Notificacao {
  id: number;
  usuario_criacao_id: number;
  usuario_destino_id: number;
  notificacao: string;
  data_hora: string;
  ativa: boolean;
  link_notificacao: JSON;
  arquivos: Arquivo[];
}

@Injectable({
  providedIn: 'root'
})
export class NotificacaoService {

  constructor(
    private apiService: ApiService,
    private overlayService: OverlayService
  ) { }

  async enviaNotificacao(usuarios: number[], texto: string, files: File[]): Promise<void> {
    return new Promise((resolve, reject) => {
      this.apiService.sendDataAndFile(
        {
          usuarios: usuarios,
          texto: texto
        },
        files,
        'envia_notificacao'
      ).subscribe(
        () => {
          this.overlayService.toast(
            {
              message: "Notificações enviadas com sucesso.",
              color: 'success'
            }
          );
          resolve();
        },
        (erro) => {
          let msg = '';
          if (erro.error != undefined) {
            msg = erro.error.message
          } else {
            msg = erro.message
          }
          this.overlayService.toast(
            {
              message: msg,
              color: 'danger'
            }
          )
          reject();
        }
      )
    })
  }

  async getAllNotificacoesUsuario(): Promise<Notificacao[]> {
    return new Promise((resolve, reject) => {
      this.apiService.syncData(
        'get_notificacoes'
      ).subscribe(
        (retorno: Notificacao[]) => {
          resolve(retorno);
        },
        (erro) => {
          let msg = '';
          if (erro.error != undefined) {
            msg = erro.error.message
          } else {
            msg = erro.message
          }
          this.overlayService.toast(
            {
              message: msg,
              color: 'danger'
            }
          )
          reject();
        }
      )
    });
  }

  async setNotificacaoLida(notificacaoId: number, ativa: boolean = false): Promise<void> {
    return new Promise((resolve, reject) => {
      this.apiService.sendData(
        {
          id: notificacaoId,
          ativa: ativa
        },
        "set_notificacao"
      ).subscribe(
        () => {
          resolve();
        },
        (erro) => {
          let msg = '';
          if (erro.error != undefined) {
            msg = erro.error.message
          } else {
            msg = erro.message
          }
          this.overlayService.toast(
            {
              message: msg,
              color: 'danger'
            }
          )
          reject();
        }
      );
    });
  }
}
