import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { LoadingController, ToastController } from '@ionic/angular';
import { ApiService } from 'src/app/api/api.service';
import { Router } from '@angular/router';



export interface Imagem {
  id: number;
  nome: string;  
  arquivo: any;
}

const TABELA = "imagens"

@Injectable({
  providedIn: 'root'
})
export class ImagensService {
  loading: any;

  constructor(
    private storage: Storage,
    private apiService: ApiService,
    private toastController: ToastController,
    public loadingController: LoadingController,
    private router: Router,
  ) { }

  deleteImagem(imagem: Imagem): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.apiService.sendData(
        imagem,
        "delete_imagem"
      ).subscribe(
        (retorno) => {
          this.showMensage("Registro excluido com sucesso.", 'success');
          resolve(true);
        },
        (erro) => {
          let msg = '';
          if (erro.error != undefined) {
            msg = erro.error.message
          } else {
            msg = erro.message
          }
          this.showMensage(msg, 'danger')
          return false;
        }
      );
    });
  }

  getImagensById(id) {
    return this.storage.get(TABELA).then(
      (imagens) => {
        return imagens.find(
          (imagem) => imagem.id === parseInt(id)
        )
      }
    );
  }

  getAllImagens(): Promise<Imagem[]> {
    return this.storage.get(TABELA);
  }

  envioDadosImagem(imagem: Imagem): any {
    this.apiService.sendDataAndFile(
      imagem,
      [imagem.arquivo],
      "set_imagem"
    ).subscribe(
      (retorno) => {        
        this.showMensage("Registro inserido/alterado com sucesso.", 'success');
        this.setRetornoApi(imagem, retorno);
        this.router.navigate(['imagens'], { skipLocationChange: false });
      },
      (erro) => {
        let msg = '';
        if (erro.error != undefined) {
          msg = erro.error.message
        } else {
          msg = erro.message
        }
        this.showMensage(msg, 'danger')
        return false;
      }
    );
  }


  async sincronizaImagens(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.showLoading("Sincronizando imagens...").then(
        () => {
          return this.apiService.syncData('sincroniza_imagens').subscribe(
            (retorno) => {
              this.addImagemSync(retorno).then(()=>{
                this.loadingController.dismiss('sincroniza_imagens');
                resolve(true);
              });
            },
            (erro) => {
              
              let msg = '';
              if (erro.error != undefined) {
                msg = erro.error.message
              } else {
                msg = erro.message
              }
              this.showMensage("Erro ao sincronizar imagens. :" + msg, 'danger');
              this.loadingController.dismiss('sincroniza_imagens');
              resolve(false);
            }
          );
        }
      );
    });
  }

  async addImagemSync(imagens: Imagem[]): Promise<any> {  
    this.storage.set(TABELA, []);     
    return this.storage.set(TABELA, imagens);
  }

  

  private async addImagem(imagem: Imagem): Promise<any> {
    return this.storage.get(TABELA).then((imagens: Imagem[]) => {
      if (imagens) {
        imagens.push(imagem);
        this.storage.set(TABELA, imagens);
      } else {
        this.storage.set(TABELA, [imagem]);
      }
    });
  }

  private updateImagem(imagem: Imagem): Promise<any> {
    return this.storage.get(TABELA).then((imagens: Imagem[]) => {
      if (!imagens || imagens.length === 0) {
        return null;
      }

      let newImagem: Imagem[] = [];

      for (let i of imagens) {
        if (i.id === imagem.id) {
          newImagem.push(imagem);
        } else {
          newImagem.push(i);
        }
      }

      return this.storage.set(TABELA, newImagem);
    });
  }

  private setRetornoApi(imagem: Imagem, retorno) {
    if (imagem.id != null && imagem.id != undefined) {
      this.updateImagem(imagem);
    }
    else {
      imagem.id = retorno.id;
      this.addImagem(imagem);
    }
  }

  private async showMensage(msg, color) {
    const toast = await this.toastController.create({
      message: msg,
      color: color,
      duration: 10000
    });
    toast.present();
  }

  private async showLoading(msg: string) {
    await this.loadingController.create({
      message: msg,
      spinner: 'crescent',
      id: 'sync_imagens'
    }).then(
      (v) => {
        this.loading = v;
        v.present();
      }
    );

  }

}
