import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { Arquivo, AuditoriaTesteControleConstatacaoService } from 'src/app/services/auditoria-teste-controle-constatacao.service';
import { saveAs as importedSaveAs } from "file-saver";
import { AuditoriaTesteControleConstatacaoSolicitacaoInformacao } from 'src/app/services/auditoria/teste-controle-constatacao-solicitacao-informacao.service';
import { OverlayService } from 'src/app/core/services/overlay.service';

@Component({
  selector: 'app-modal-anexo',
  templateUrl: './modal-anexo.page.html',
  styleUrls: ['./modal-anexo.page.scss'],
})
export class ModalAnexoPage implements OnInit {
  arquivos: Arquivo[] = [];
  registro: AuditoriaTesteControleConstatacaoSolicitacaoInformacao = {} as AuditoriaTesteControleConstatacaoSolicitacaoInformacao;

  constructor(
    private modalController: ModalController,
    private params: NavParams,
    private auditoriaTesteControleConstatacaoService: AuditoriaTesteControleConstatacaoService,
    private overlayService: OverlayService
  ) { }

  ngOnInit() {
    this.registro = this.params.data.registro;
    if ((this.params.data.arquivos !== null) && (!!this.params.data.arquivos[0].id)) {
      this.arquivos = this.params.data.arquivos;
    } else {
      this.arquivos = undefined
    }
  }

  async closeModal() {
    await this.modalController.dismiss({
      atualiza: false
    });
  }

  async deletaArquivo(arquivo: Arquivo): Promise<void> {
    
    let dataHoraFimTeste = new Date(this.registro.data_limite_envio_solicitacao_informacao);
    let dataHoraAtual = new Date();    
    // Obter a data/hora atual
    
    if (
      ( dataHoraAtual.getTime() > dataHoraFimTeste.getTime() )
    ) {
      this.overlayService.alert({
        message: "Atenção, não é possível excluir um anexo após a data fim do envio das informações solicitadas.",
        buttons: [
          {
            text: "Ok"
          }
        ]
      })
    } else {
      if (
        (this.registro.ct_parcialmente_auditado)
      ) {
        this.overlayService.alert({
          message: "Atenção, não é possível excluir um anexo de um CT totalmente ou parcialmente auditado.",
          buttons: [
            {
              text: "Ok"
            }
          ]
        })
      } else {
        this.overlayService.alert({
          header: "Atenção!",
          message: "Deseja mesmo excluir o arquivo? ",
          buttons: [
            {
              text: "Sim",
              handler: async () => {
                await this.auditoriaTesteControleConstatacaoService.deletaAuditoriaArquivo(
                  this.registro.id,
                  arquivo.id
                );
                this.modalController.dismiss({
                  atualiza: true
                });
                this.overlayService.toast(
                  {
                    message: "Arquivo excluído com sucesso!",
                    color: "success"
                  }
                );
              }
            },
            {
              text: "Não"
            }
          ]
        })
      }
    }
  }

  downloadFile(arquivo: Arquivo) {
    this.auditoriaTesteControleConstatacaoService.getAuditoriaArquivo(
      arquivo.id
    ).subscribe(
      (retorno) => {
        importedSaveAs(retorno, arquivo.nome)
      },
      (erro) => {
      }
    )
  }
}
