import { Injectable } from "@angular/core";
import { Storage } from "@ionic/storage";
import { ApiService } from "../api/api.service";

const TABELA = "auditoria_objetivo_controle";

export interface AuditoriaObjetivoControle {
  id: number;
  descricao: string;
}

@Injectable({
  providedIn: "root",
})
export class AuditoriaObjetivoControleService {
  constructor(private storage: Storage, private apiService: ApiService) {}

  sincronizaAuditoriaObjetivoControle() {
    return this.apiService
      .syncData("sincroniza_auditoria_objetivo_controle")
      .subscribe(
        (retorno) => {
          if (retorno.length > 0) {
            this.addAuditoriaObjetivoControleSync(retorno);
          }
        },
        (erro) => {}
      );
  }

  getAuditoriaObjetoControleById(id): Promise<AuditoriaObjetivoControle> {
    return this.storage.get(TABELA).then((registros) => {
      return registros.find((registro) => registro.id === parseInt(id));
    });
  }

  getAllAuditoriaObjetoControle(): Promise<AuditoriaObjetivoControle[]> {
    return this.storage.get(TABELA);
  }

  async addAuditoriaObjetivoControleSync(
    registros: AuditoriaObjetivoControle[]
  ): Promise<any> {
    return this.storage.set(TABELA, registros);
  }
}
