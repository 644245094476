import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { Ano, AnoService } from 'src/app/services/ano.service';

@Component({
  selector: 'app-filtra-registros',
  templateUrl: './filtra-registros.page.html',
  styleUrls: ['./filtra-registros.page.scss'],
})
export class FiltraRegistrosPage implements OnInit {
  ano: Ano = {} as Ano;
  anos: Ano[] = [];
  statuss:any = [{id: 1, nome: 'Em execução', sigla: 'E'}, {id:2, nome: 'Finalizado', sigla: 'F'}]
  status:any = undefined;
  constructor(
    private modalController: ModalController,
    private params: NavParams,
    private anoService: AnoService
  ) { }

  ngOnInit() {
    this.anoService.getAllAno().then(
      (anos: Ano[]) => {
        this.anos = anos.sort((a, b) => (a.ano as any) - (b.ano as any))
      }
    );
    if (this.params.data.ano_id != undefined) {
      this.anoService.getAnoById(this.params.data.ano_id).then(
        (ano: Ano) => {
          this.ano = ano;
        }
      );
    } else {
      this.ano = undefined;
    }
  }

  async closeModal() {
    await this.modalController.dismiss();
  }

  async confirmModal() {
    let retorno = {};  
    if (this.status != undefined) {
      retorno["status"] = this.status.sigla;
    } else {
      retorno["status"] = undefined;
    }
    if (this.ano != undefined) {
      retorno["ano_id"] = this.ano.id;
    } else {
      retorno["ano_id"] = undefined;
    }     
    await this.modalController.dismiss(retorno);
  }

}
