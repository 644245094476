import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { OverlayService } from 'src/app/core/services/overlay.service';
import { FiscalizacaoProcesso, FiscalizacaoProcessoService } from 'src/app/services/fiscalizacao-processo.service';
import { CtsProcessoFiscalizacao, ProcessoFiscalizacaoService } from 'src/app/services/fiscalizacao/processo-fiscalizacao.service';

@Component({
  selector: 'app-modal-seta-teste-fiscalizacao',
  templateUrl: './modal-seta-teste-fiscalizacao.page.html',
  styleUrls: ['./modal-seta-teste-fiscalizacao.page.scss'],
})
export class ModalSetaTesteFiscalizacaoPage implements OnInit {

  testeFiscalizacaoList: CtsProcessoFiscalizacao[] = [];

  ciclo: String = undefined;

  fiscalizacaoProcesso: FiscalizacaoProcesso = {} as FiscalizacaoProcesso;
  procesos: FiscalizacaoProcesso[] = [];

  constructor(
    private modalController: ModalController,
    private processoFiscalizacaoService: ProcessoFiscalizacaoService,
    private fiscalizacaoProcessoService: FiscalizacaoProcessoService,
    private params: NavParams,
    private overlayService: OverlayService,
  ) { }

  selecionaTodos(ev): void {
    let valor = ev.detail.checked;
    this.testeFiscalizacaoList.forEach(
      (el: CtsProcessoFiscalizacao) => {
        el.selecionado = valor;
      }
    );
  }

  ngOnInit() {
    this.processoFiscalizacaoService.getCTSProcessoFiscalizacao(this.params.data.processoFiscalizacaoId).then(
      (regs: CtsProcessoFiscalizacao[]) => {
        this.testeFiscalizacaoList = regs;
      }
    );

    this.fiscalizacaoProcesso = undefined;
    this.fiscalizacaoProcessoService.getAllFiscalizacaoProcesso().then(
      (registros: FiscalizacaoProcesso[]) => {
        this.procesos = registros;
      }
    )
  }

  async closeModal(): Promise<void> {
    await this.modalController.dismiss();
  }

  async confirmModal(): Promise<void> {
    const loading = await this.overlayService.loading({ message: "Salvando..." });
    await this.processoFiscalizacaoService.setCtsProcessoFiscalizacao(
      this.params.data.processoFiscalizacaoId,
      this.testeFiscalizacaoList
    ).then(
      () => {
        loading.dismiss();
        this.modalController.dismiss();
      }
    )
  }

}
