import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/api/api.service';
import { OverlayService } from 'src/app/core/services/overlay.service';

@Injectable({
  providedIn: 'root'
})
export class GerenciarImagensService {

  constructor(
    private apiService: ApiService,
    private overlayService: OverlayService
  ) { }

  async getAllImagens(params: any): Promise<any> {
    /*const loading = await this.overlayService.loading({
      message: "Aguarde..."
    })*/
    return new Promise((resolve, reject) => {    
      this.apiService.sendData(
        params,
        "sincroniza_imagens"
      ).subscribe(
        (retorno) => {
          //loading.dismiss();   
          resolve(retorno);          
        },
        () => {
          //loading.dismiss();
          reject();
        }
      )
    })
  }

  async getValues(params: any): Promise<any> {
    const loading = await this.overlayService.loading({
      message: "Aguarde..."
    })
    return new Promise((resolve, reject) => {    
      this.apiService.sendData(
        params,
        "get_configuracao_sistema"
      ).subscribe(
        (retorno) => {
          loading.dismiss();   
          resolve(retorno);          
        },
        () => {
          loading.dismiss();
          reject();
        }
      )
    })
  }

  async setValues(params: any): Promise<any> {
    const loading = await this.overlayService.loading({
      message: "Aguarde..."
    })
    return new Promise((resolve, reject) => {    
      this.apiService.sendData(
        params,
        "set_configuracao_sistema"
      ).subscribe(
        (retorno) => {
          loading.dismiss();   
          resolve(retorno);          
        },
        () => {
          loading.dismiss();
          reject();
        }
      )
    })
  }

  async getImage(params: any): Promise<any> {    
    return new Promise((resolve, reject) => {    
      this.apiService.sendData2(
        params,
        "find_configuracao_sistema"
      ).subscribe(
        (retorno) => {          
          resolve(retorno);          
        },
        () => {          
          reject();
        }
      )
    })
  }

  async getImage2(params: any): Promise<any> {    
    return new Promise((resolve, reject) => {    
      this.apiService.sendData2(
        params,
        "find_configuracao_imagens"
      ).subscribe(
        (retorno) => {          
          resolve(retorno);          
        },
        () => {          
          reject();
        }
      )
    })
  }
}
