import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { DadoUsuario } from 'src/app/services/dado-municipio-prestador.service';
import { Dado, DadoService } from 'src/app/services/dado.service';

@Component({
  selector: 'app-edita-registros',
  templateUrl: './edita-registros.page.html',
  styleUrls: ['./edita-registros.page.scss'],
})
export class EditaRegistrosPage implements OnInit {

  dado: Dado = {} as Dado;
  dadoUsario: DadoUsuario = {} as DadoUsuario;

  val_jan: string;
  val_fev: string;
  val_mar: string;
  val_abr: string;
  val_mai: string;
  val_jun: string;
  val_jul: string;
  val_ago: string;
  val_set: string;
  val_out: string;
  val_nov: string;
  val_dez: string;
  val_tot: number;


  constructor(
    private modalController: ModalController,
    private params: NavParams,
    private dadoService: DadoService
  ) { }

  async closeModal() {
    await this.modalController.dismiss();
  }

  async confirmModal() {
    let newValue: JSON = {} as JSON;
    newValue[1] = this.val_jan;
    newValue[2] = this.val_fev;
    newValue[3] = this.val_mar;
    newValue[4] = this.val_abr;
    newValue[5] = this.val_mai;
    newValue[6] = this.val_jun;
    newValue[7] = this.val_jul;
    newValue[8] = this.val_ago;
    newValue[9] = this.val_set;
    newValue[10] = this.val_out;
    newValue[11] = this.val_nov;
    newValue[12] = this.val_dez;
    this.dadoUsario.valor = newValue
    await this.modalController.dismiss(
      this.dadoUsario
    );
  }

  ngOnInit() {
    this.dadoUsario = this.params.data.registro;
    this.dadoService.getDadoById(this.dadoUsario.dado_id).then(
      (dado: Dado) => {
        this.dado = dado;
      }
    ).then(
      () => {
        this.setVariaveis();
      }
    )
  }

  private setVariaveis() {
    this.val_jan = this.dadoUsario.valor[1];
    this.val_fev = this.dadoUsario.valor[2];
    this.val_mar = this.dadoUsario.valor[3];
    this.val_abr = this.dadoUsario.valor[4];
    this.val_mai = this.dadoUsario.valor[5];
    this.val_jun = this.dadoUsario.valor[6];
    this.val_jul = this.dadoUsario.valor[7];
    this.val_ago = this.dadoUsario.valor[8];
    this.val_set = this.dadoUsario.valor[9];
    this.val_out = this.dadoUsario.valor[10];
    this.val_nov = this.dadoUsario.valor[11];
    this.val_dez = this.dadoUsario.valor[12];
    this.setValTotal();
  }

  setValTotal() {
    if (this.dado.tipo == "U") {
      this.val_tot = parseFloat(this.val_dez);
    } else {
      this.val_tot = parseFloat(this.val_jan) + parseFloat(this.val_fev) + parseFloat(this.val_mar) + parseFloat(this.val_abr) +
        parseFloat(this.val_mai) + parseFloat(this.val_jun) + parseFloat(this.val_jul) + parseFloat(this.val_ago) +
        parseFloat(this.val_set) + parseFloat(this.val_out) + parseFloat(this.val_nov) + parseFloat(this.val_dez);
    }
  }

}
